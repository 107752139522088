/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  AddCultureButton,
  Box,
  ButtonContainer,
  Container,
  ContainerTagCultureAndPlantedArea,
  Footer,
  Input,
  Label,
} from "./styled";
import { api } from "data/protocols/services/api";
import { Cultures, Channels, IncludeListCultures, AgregaCulture } from "./types";
import { Dropdown } from "./DropDown";
import { PlusIcon } from "./PlusIcon";
import { TagCultureAndPlantedArea } from "./TagCultureAndPlantedArea";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setOptInAgrega } from "store/slices/user";

interface AdditionalInformationProps {
  $setIsOpenAccordion: (isOpenAccordion: boolean) => void;
}

export const AdditionalInformation = ({$setIsOpenAccordion}: AdditionalInformationProps) => {
  const initialCultureState: Cultures = {
    createdAt: "",
    id: "",
    name: "",
    updatedAt: null,
  };

  const initialChannelsState: Channels = {
    id: "",
    nameGroup: "",
  };

  const dispatch = useDispatch();

  const [acceptedAgrega, setAcceptedAgrega] = useState(false);

  const [cultures, setCultures] = useState<Cultures[]>([]);
  const [cultureSelect, setCultureSelect] = useState<Cultures>(initialCultureState);
  const [includeListCultures, setIncludeListCultures] = useState<Array<IncludeListCultures>>([]);

  const [channels, setChannels] = useState<Channels[]>([]);
  const [channelSelect, setChannelSelect] = useState<Channels>(initialChannelsState);

  const [valueHectares, setValueHectares] = useState<string>("");

  const [disabledButton, setDisabledButton] = useState(true);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);

  const [indication, setIndication] = useState<string>("");

  const hasCultureAndHectares = cultureSelect && valueHectares;

  const fetchApis = async () => {
    try {
      const [responseCultures, responseChannels] = await Promise.all([
        api.getCultures(),
        api.getChannels(),
      ]);
      setCultures(responseCultures.data.data.rows);
      setChannels(responseChannels.data.data.rows);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchApis();
  }, []);

  const handleValueHectares = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueHectares(onlyNumbers(event.target.value));
 };

 const handleIndication = (event: React.ChangeEvent<HTMLInputElement>) => {
   setIndication(event.target.value);
 };

  const onlyNumbers = (input: string) => {
    if (input === "0") {
      return '';
    }
    return input.replace(/\D/g, "");
  }

  const resetFormFields = () => {
    if (hasCultureAndHectares) {
      setCultureSelect(initialCultureState);
      setValueHectares("");
    }
  };

  const handleAddCulture = () => {
    if (hasCultureAndHectares) {
      setIncludeListCultures((prev) => [...prev, { culture: cultureSelect, hectar: parseInt(valueHectares) }]);
      setCultures((prev) => prev.filter((culture) => culture.name !== cultureSelect.name));
      resetFormFields();
    }
  };

  const handleRemoveCulture = (culture: Cultures) => {
    setIncludeListCultures((prev) => prev.filter((item) => item.culture.name !== culture.name));
    setCultures((prev) => [...prev, culture].sort((a, b) => a.name.localeCompare(b.name)));
  };

  async function handleOptInAgrega() {
    const culturesHectares: AgregaCulture[] = includeListCultures.map((item) => {
      return {
        culture: item.culture.name,
        cultureId: item.culture.id,
        hectares: item.hectar,
      }
    });
    const payload = {
        culturesHectares: culturesHectares,
        distribuitorId: channelSelect.id,
        optInAgrega: true,
        optInPoints: true,
        optinComunication: "0",
        optinTermsAndPrivacy: true,
        promotionCode: indication
    }
    try {
      const response = await api.postOptinAgrega(payload);
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 2000,
          position: "top-right",
        });

        dispatch(setOptInAgrega("true"));

        $setIsOpenAccordion(false);

        localStorage.setItem("optinAgrega", "true");

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error: any) {
        toast.error(error.response?.data.message || error?.message || "Ocorreu um erro ao enviar a solicitação", {
          autoClose: 3000,
          position: "top-right",
        });

        console.error(error);
    }
  } 

  useEffect(() => {
    setDisabledButton(!cultureSelect || !valueHectares);
  }, [cultureSelect, valueHectares]);

  useEffect(() => {
    const hasDisabled = includeListCultures.length === 0 || channelSelect.id === "" || acceptedAgrega === false;

    if (hasDisabled) {
      setDisabledSubmitButton(true);
    } else {
      setDisabledSubmitButton(false);
    }

  }, [includeListCultures, channelSelect, acceptedAgrega]);

  return (
    <>
      <Container>
        <Box>
          <div>
            <Label htmlFor="cultures">
              Cultura <span>*</span>
            </Label>
            <Dropdown
              id="cultures"
              options={cultures}
              selected={cultureSelect}
              onSelect={setCultureSelect}
              setOptions={setCultures}
              placeholder="Sua cultura"
            />
          </div>
          <div>
            <Label htmlFor="cultures">
              Área plantada (ha)<span>*</span>
            </Label>
            <Input
              type="text"
              id="hectares"
              name="hectares"
              value={valueHectares}
              onChange={handleValueHectares}
              maxLength={6}
            />
            <div className="add-culture">
              <AddCultureButton disabled={disabledButton} onClick={handleAddCulture}>
                <PlusIcon $disabled={disabledButton} />
                Adicionar cultura
              </AddCultureButton>
            </div>
          </div>
        </Box>

        <ContainerTagCultureAndPlantedArea>
          {includeListCultures.length > 0 &&
            includeListCultures.map((item) => (
              <TagCultureAndPlantedArea
                key={item.culture.id}
                name={item.culture.name}
                $hectar={item.hectar}
                onClick={() => handleRemoveCulture(item.culture)}
              />
            ))}
        </ContainerTagCultureAndPlantedArea>

        <Box>
          <div>
            <Label htmlFor="cultures">
              Canal <span>*</span>
            </Label>
            <Dropdown
              id="channels"
              onSelect={setChannelSelect}
              options={channels.filter(
                (channel) => channel.nameGroup !== "" && channel.nameGroup !== null
              )}
              setOptions={setChannels}
              placeholder="Selecione"
              selected={channelSelect}
            />
          </div>
          <div>
            <Label htmlFor="cultures">Indicação/Promoção</Label>
            <Input type="text" id="cupom" name="cupom" value={indication} onChange={handleIndication}/>
          </div>
        </Box>

        <Footer>
          <div>
            <label htmlFor="acceptedAgrega">
              <input
                type="checkbox"
                id="acceptedAgrega"
                name="acceptedAgrega"
                checked={acceptedAgrega}
                onChange={() => setAcceptedAgrega(!acceptedAgrega)}
              />
              <span className="checkmark acceptedAgrega"></span>
              <span className="acceptedAgrega">
                <b>*</b> Eu li e concordo com o{" "}
                <a
                  href="https://staccobasfagregaprd.blob.core.windows.net/agrega-regulamento/2023.09.14_NOVO%20AGREGA_VF.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Regulamento AGREGA
                </a>
              </span>
            </label>
          </div>
        </Footer>
      </Container>

      <ButtonContainer>
        <button disabled={disabledSubmitButton} onClick={handleOptInAgrega}>Salvar</button>
      </ButtonContainer>
    </>
  );
};
