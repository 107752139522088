/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "data/protocols/services/api";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "store/selectors/product";
import { setProducts, filterProducts } from "store/slices/product";

const useFilterProduct = () => {
  const [category, setCategory] = useState("");
  const [productName, setProductName] = useState("");

  const categories = [
    { label: "Estratégico", value: "Estratégico" },
    { label: "Estrela", value: "Estrela" },
    { label: "Lançamento", value: "Lançamento" },
  ];

  const dispatch = useAppDispatch();
  const errorMessage = "Não conseguimos encontrar nenhum dado";

  const getSearchProduct = async () => {
    try {
      const response = await api.fetchGetProducts();

      dispatch(setProducts(response));

      dispatch(filterProducts({ category, productName }));
      
    } catch (err: any) {
      toast.error(err.response?.message || errorMessage);
    }
  };

  return {
    categories,
    category,
    errorMessage,
    getSearchProduct,
    productName,
    setCategory,
    setProductName,
  };
};

export default useFilterProduct;
