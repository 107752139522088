import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
`

export const TableContainer = styled.table`
    width: 100%;
    border-collapse: collapse;
    th, td {
        padding: 10px;
        text-align: left;
    }
`
export const Thead = styled.thead`
    background-color: #E2E2E2;
    font-size: 0.85rem;
`
export const Tbody = styled.tbody`
    font-size: 0.85rem;
    tr, td {
        border: 1px solid #DDD;
    }
`
export const PaginationContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: right;
    padding: 1rem 0;
    width: 35%;
    margin: 0 0 0 auto;
    button {
        border: 0;
        border-radius: 0.4rem;
        background-color: #eee;
        padding: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
        margin: 0 0.15rem;
        height: 2rem;
        cursor: pointer;
    }

    span {
        font-size: 0.85rem;
    }

    svg {
        width: 1.2rem;
        margin-top: -0.25rem;
    }
`