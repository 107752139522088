import styled from "styled-components";
import { colors } from "themes/colors";

interface DropListItemProps {
  $isHighlighted?: boolean;
  $screenWidth?: number
}

export const DropListContainer = styled.div<{width?:string}>`
  position: relative;
  display: flex;
  width: ${(props) => props.width ? props.width : "100%"};
`;

export const DropListButton = styled.div<DropListItemProps>`
    font-size: 20px;
    padding: 8px 18px 8px 18px;
    border-radius: 30px;
    background-color: #fff;
    border: 1px solid #ccc;
    height: 18px;
    width: 100%;

    cursor: pointer;

    span {
      font-family: "PoppinsLight", Arial, sans-serif;
      font-size: 0.8rem;
    }
`;

export const DropListMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-bottom: 1px solid ${colors.gray.light};
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px;
  z-index: 1000;
  overflow-y: auto;
`;

export const DropListItem = styled.li<DropListItemProps>`
  font-family: "PoppinsLight", Arial, sans-serif;
  font-size: 0.8rem;
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.$isHighlighted ? '#f0f0f0' : 'transparent')};
  color: ${(props) => (props.$isHighlighted ? '#333' : '#000')};

  &:hover {
    background-color: "red";
  }
`;

