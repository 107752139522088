export function useDecodeToken(token: string) {
    if (!token) return

    const partes = token?.split('.');
  
    if (partes?.length !== 3) {
      throw new Error('Token JWT inválido');
    }
  
    const payloadBase64 = partes[1];
    
    const padding = payloadBase64.length % 4 === 0 ? '' : '='.repeat(4 - (payloadBase64.length % 4));
    const base64 = payloadBase64 + padding;
  
    const jsonPayload = JSON.parse(atob(base64));
    
    return jsonPayload;
}
  