/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { api } from 'data/protocols/services/api';
import { useAppDispatch, useAppSelector } from "../store/selectors/distributor";
import { setDistributors } from "../store/slices/distributor";

const useDistributors = () => {
  const dispatch = useAppDispatch();
  const distributorData =  useAppSelector((state) => state.distributor.distributors);
  
  useEffect(() => {
    const fetchDistributors = async () => {
      try {
        const response: any = await api.fetchGetDistributors();
        dispatch(setDistributors(response.data.rows));
      } catch (err: any) {
        console.log("error", err)
      }
    };
    fetchDistributors();
  }, []); 
  return { distributorData };
};

export default useDistributors;
