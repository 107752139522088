/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  Container,
  PaginationContainer,
  TableContainer,
  Tbody,
  Thead,
} from "./styled";
import { TableProps } from "./types";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import useDistributors from "hooks/useDistributors";
import useProducts from "../../../../hooks/useProducts";

export const Table = (props: TableProps) => {

  const [currentPage, setCurrentPage] = useState(1);
  const { productsData } =  useProducts();
  const { distributorData } = useDistributors();
 
  const rowsPerPage = 10;

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (props.type === "products") {
      setData(productsData);
    } else if (props.type === "distributors") {
      setData(distributorData);
    }
  }, [productsData, distributorData]);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, data.length);

  const handleFirstPage = () => setCurrentPage(1);
  const handleLastPage = () => setCurrentPage(totalPages);
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <Container>
      <TableContainer>
        <Thead>
          {props.type === "products" && (
            <tr>
              <th>Categoria</th>
              <th>Produto</th>
              <th>Ponto</th>
            </tr>
          )}
          {props.type === "distributors" && (
            <tr>
              <th>Estado</th>
              <th>Distribuidor</th>
              <th>Cidade</th>
              <th>Bairro</th>
              <th>Logradouro</th>
              <th>Número</th>
            </tr>
          )}
        </Thead>
        <Tbody>
          {currentData.length > 0 &&
          currentData.map((item: any, index: number) => (
            <tr key={index}>
              {props.type === "products" ? (
                <>
                  <td>{item.category}</td>
                  <td>{item.productName}</td>
                  <td>{item.point}</td>
                </>
              ) : (
                <>
                  <td>{item.state}</td>
                  <td>{item.name}</td>
                  <td>{item.city}</td>
                  <td>{item.neighborhood}</td>
                  <td>{item.address}</td>
                  <td>{item.number}</td>
                </>
              )}
            </tr>
          ))}{
            currentData.length === 0 && (
              <tr>
                <div style={{padding: 5}}>Nenhum registro encontrado.</div>
              </tr>
            )
          }
        </Tbody>
      </TableContainer>
      <PaginationContainer>
        <div>
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            <KeyboardDoubleArrowLeftIcon />
          </button>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            <KeyboardArrowLeftIcon />
          </button>
        </div>
        <span>
          {startIndex}-{endIndex} de {data.length}
        </span>
        <div>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <KeyboardArrowRightIcon />
          </button>
          <button
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
          >
            <KeyboardDoubleArrowRightIcon />
          </button>
        </div>
      </PaginationContainer>
    </Container>
  );
};
