import styled from 'styled-components';
import { colors } from '../../../../../themes/colors';

export const TagContainer = styled.button`
  background-color: ${colors.white.default};
  border: 1px solid red;
  border-radius: 20px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 4rem;
  max-width: 14rem;
  margin-bottom: 0.2rem;

  cursor: default;
`;

export const TagText = styled.span`
  font-family: "PoppinsLight", Arial, sans-serif !important;
  margin-right: 5px;
  font-size: 0.8rem;
  font-family: ComfortaaRegular;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: ${colors.red};
  margin-top: 0;
`;

export const CloseIcon = styled.span`
  font-family: "PoppinsLight", Arial, sans-serif !important;
  font-size: 16px;
  color: ${colors.red};

  cursor: pointer;
`;