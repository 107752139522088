import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { FC, SyntheticEvent, useState, useEffect } from "react";
import { AccordionProps } from "design-system/domain/components/accordion";
import { AccordionStyled } from "./styled";
import { colors } from "design-system/theme";

const Accordions: FC<AccordionProps> = ({
  accordionAgrega,
  accordionContent,
  accordionIcon,
  accordionTitle,
  isCampaingContent,
  isOpen = false
}) => {
  const [expanded, setExpanded] = useState<string | false>(isOpen ? "panel1" : false);

  useEffect(() => {
    setExpanded(isOpen ? "panel1" : false);
  }, [isOpen]);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <AccordionStyled.AccordionContainer>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={accordionIcon}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            sx={{
              color: colors.conectaTheme.primary,
              flexShrink: 0,
              fontSize: 16,
              fontWeight: "700",
              width: "100%",
            }}
          >
            {accordionTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ color: colors.neutral.gray04, fontSize: 16 }}>
            {isCampaingContent ? (
              <p
                className="content-acc"
                dangerouslySetInnerHTML={{ __html: accordionContent }}
              />
            ) : (
              accordionContent
            )}
          </Typography>
          {accordionAgrega}
        </AccordionDetails>
      </Accordion>
    </AccordionStyled.AccordionContainer>
  );
};

export default Accordions;
