/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { api } from 'data/protocols/services/api';
import { useAppDispatch } from "../store/selectors/distributor";
import { setDistributors } from "../store/slices/distributor";
import { toast } from 'react-toastify';

const useFilterDistributor = () => {
  const [distributor, setDistributor] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");
  const dispatch = useAppDispatch();
  let errorMessage = "Não conseguimos encontrar nenhum dado"
  const getchSearchDistributor = async (distributor: string, uf: string, city: string) => {
    try {
      const response = await api.fetchGetSearchDistributors(distributor, city, uf);
      dispatch(setDistributors(response.data.rows));
      if( response.data.rows.length === 0){
        errorMessage = response.message
      }
    } catch (err: any) {
      toast.error(err.response?.message);
    }
  };
  /* eslint-disable sort-keys */
  return { 
    city,
    distributor,
    getchSearchDistributor,
    errorMessage,
    setCity,
    setDistributor,
    setUf,
    uf 
  };
};

export default useFilterDistributor;
