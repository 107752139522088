import styled from "styled-components";
import checkedIcon from "../assets/checked-icon.png";

export const Container = styled.div`
  gap: 1rem;
  margin: 1rem 0;
`;
export const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;

  .cultures {
    border-radius: 1rem;
  }

  .add-culture {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1196px) {
    grid-template-columns: repeat(2, 1fr);
  }

`;
export const Label = styled.label`
  font-size: 0.85rem;
  margin-left: 0.75rem;

  span {
    color: #fc3229;
    font-weight: 600;
    font-size: 0.7rem;
  }
`;
export const Input = styled.input`
  padding: 0.65rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  border-radius: 1.3rem;
  border: 1px solid #ccc;
  width: 90%;

  :focus {
    transition: 0.2s;
    outline: none;
    border: 1px solid #fc3229;
  }
`;
export const Select = styled.select`
  font-family: "PoppinsLight", Arial, sans-serif;
  padding: 0.35rem;
  padding-left: 0.5rem;
  border-radius: 1rem;
  border: 1px solid #bbb;
  width: 90%;
  color: #ccc;

  option {
    color: #333;
  }

  :focus {
    transition: 0.2s;
    outline: none;
    border: 1px solid #fc3229;
  }
`;
export const AddCultureButton = styled.button<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PoppinsLight", Arial, sans-serif;
  background-color: transparent;
  border: none;
  color: #fc3229;
  font-size: 0.75rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  gap: 0.5rem;

  :disabled {
    color: #aaa;

    cursor: not-allowed;
  }

  cursor: pointer;
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    margin-top: 0;

    label {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    @media (min-width: 1024px) {
      margin-top: 2rem;
    }

    @media (min-width: 2560px) {
      .emailSms {
        margin-top: 0.85rem;
      }
    }

    label input[type="checkbox"] {
      display: none;
    }

    label .checkmark {
      width: 1.5rem;
      height: 1.5rem;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 0.6rem;
      display: inline-block;
      margin-right: 1rem;

      cursor: pointer;

      @media (min-width: 1024px) {
        width: 0.9rem;
        height: 0.9rem;
        margin-right: 1rem;
        border-radius: 0.25rem;
      }
    }

    label .checkmark::after {
      content: "";
      display: none;
    }

    label input[type="checkbox"]:checked + .checkmark::after {
      display: block;
    }

    label .checkmark::after {
      font-family: "PoppinsLight", Arial, sans-serif;
      background-image: url(${checkedIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      width: 1.5rem;
      height: 1.5rem;

      @media (min-width: 1024px) {
        width: 1.15rem;
        height: 1.15rem;
        margin-top: -1px;
        margin-left: -2px;
      }
    }

    span {
      font-family: "PoppinsLight", Arial, sans-serif;
      font-size: 0.85rem;
      color: #282525;

      @media (min-width: 2550px) {
        font-size: 1rem;
      }

      a {
        font-weight: 500;
      }
    }

    a {
      text-decoration: underline;
      color: #f96e2a;

      cursor: pointer;
    }

    b {
      color: #ff2929;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    font-family: "PoppinsLight", Arial, sans-serif;
    background-color: #ff2929;
    border: none;
    color: #efefef;
    width: 100%;
    border-radius: 1rem;
    padding: 0.5rem;

    :disabled {
      background-color: #efefef;
      color: #aaa;

      cursor: not-allowed;
    }

    cursor: pointer;
  }
`;

export const ContainerTagCultureAndPlantedArea = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  min-height: 1rem;
  max-height: 6rem;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: wrap;
`
