/* eslint-disable @typescript-eslint/no-explicit-any */
import useFilterDistributor from "../../../../hooks/useFilterDistributor";
import { Box, Button, Container, Input, Label, Select } from "./styled";
import { FilterProps } from "./types";
import { states } from "./options";
import useFilterProduct from "hooks/useFilterProduct";

export const Filter = (props: FilterProps) => {
  const {
    city,
    distributor,
    getchSearchDistributor,
    setCity,
    setDistributor,
    setUf,
    uf,
  } = useFilterDistributor();

  const {
    categories,
    getSearchProduct,
    setCategory,
    productName,
    setProductName
  } = useFilterProduct();

  const handleChangeCategory = (event: any) => {
    setCategory(event.target.value);
  };

  return (
    <Container>
      {props.type === "products" && (
        <>
          <Box>
            <Label htmlFor="getProduct">Produto</Label>
            <Input
              type="text"
              id="getProduct"
              name="getProduct"
              placeholder="Buscar por produto"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}  
            />
          </Box>
          <Box>
            <Label htmlFor="getProductCategory">Categoria</Label>
            <Select id="getProductCategory" name="getProductCategory" onChange={(event) => handleChangeCategory(event)}>
              <option value="">Selecione a Categoria</option>
              {categories?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </Box>
          <Button onClick={getSearchProduct}>
            Pesquisar
          </Button>
        </>
      )}
      {props.type === "distributors" && (
        <>
          <Box>
            <Label>Distribuidor</Label>
            <Input
              type="text"
              id="getDisitribuidor"
              name="getDisitribuidor"
              onChange={(e) => setDistributor(e.target.value)}
              placeholder="Buscar por distribuidor"
            />
          </Box>
          <Box>
            <Label>Estado</Label>
            <Select id="uf" name="uf" onChange={(e) => setUf(e.target.value)}>
            <option value="">Selecione o estado</option>
              {states?.map((item: any) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <Label>Cidade</Label>
            <Input
              type="text"
              id="city"
              name="city"
              placeholder="Cidade"
              onChange={(e) => setCity(e.target.value)}
            />
          </Box>
          <Button type="submit" onClick={() => getchSearchDistributor(distributor, city, uf)}>
            Pesquisar
          </Button>
        </>
      )}
    </Container>
  );
};
