export const colors = {
    black: "#282525",
    blackGradient: "#3C3C434D",
    gray: {
        dark: "#7B7B7B",
        default: "#6C6D70",
        light: "#EDEDED",
        low: "#555555",
        medium: "#CCCCCC",
    },
    green: "#428603",
    orange: "#F58224",
    red: "#FF002C",
    white: {
        default: "#FFFFFF",
        soft: "#F9F9F9"
    }
} as const;

export type Colors = typeof colors;
