import { useEffect, useState, useCallback } from "react";
import { Container } from "./styled";

export const RegisterNF = () => {
  const [myWidth, setMyWidth] = useState(window.innerWidth);
  const [myHeight, setMyHeight] = useState(window.innerHeight);

  const sessionKey = localStorage.getItem("sessionKey");
  const token = localStorage.getItem("token");

  const iframeUrlWithID = `${process.env.REACT_APP_MFE_NF_TERRENA_AGREGA_URL}?id=${token}`;
  const iframeUrl = String(process.env.REACT_APP_MFE_NF_TERRENA_AGREGA_URL);

  const sendCredentialsToIframe = useCallback(() => {
    const credentials = {
      sessionKey,
      token,
    };

    window.addEventListener("message", (event) => {
      if (
        event.origin === iframeUrl &&
        event.data === "Solicitar credenciais"
      ) {
        if (event.source) {
          event.source.postMessage(
            credentials,
            event.origin as WindowPostMessageOptions
          );
        }
      }
    });
  }, [sessionKey, token]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "token" || event.key === "sessionKey") {
        sendCredentialsToIframe();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    sendCredentialsToIframe();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [sendCredentialsToIframe]);

  useEffect(() => {
    const handleResize = () => {
      setMyWidth(window.innerWidth);
      setMyHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container id="mfe-terrena-agrega" style={{ overflowX: "hidden" }}>
      <iframe
        id="iframeID"
        src={iframeUrlWithID}
        width={myWidth}
        height={myHeight}
        style={{ overflowX: "hidden" }}
        title="Iframe Content"
      />
    </Container>
  );
};
