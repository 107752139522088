import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Distributor {
  id: string;
  name: string;
  region: string;
}

interface DistributorState {
  distributors: Distributor[];
}
/* eslint-disable sort-keys */
const initialState: DistributorState = {
  distributors: [],
};

const distributorSlice = createSlice({
  name: "distributor",
  initialState,
  reducers: {
    setDistributors(state, action: PayloadAction<Distributor[]>) {
      state.distributors = action.payload; // Atualiza o array inteiro
    },
    clearDistributors(state) {
      state.distributors = []; // Reseta o array
    },
  },
});
/* eslint-enable sort-keys */
export const { setDistributors, clearDistributors } = distributorSlice.actions;

export default distributorSlice.reducer;
