import {
  BoxLoading,
  CircularLoadingCustomized,
  Container,
  LabelCheckTokenStatus,
} from "./styled";

interface LoadingMessageProps {
  authLoading?: boolean;
  message: string;
}

export const LoadingMessage = ({
  authLoading,
  message,
}: LoadingMessageProps) => {
  return (
    <>
      {authLoading ? (
        <BoxLoading>
          <LabelCheckTokenStatus>{message}</LabelCheckTokenStatus>
          <CircularLoadingCustomized />
        </BoxLoading>
      ) : (
        <Container>
          <BoxLoading>
            <LabelCheckTokenStatus>{message}</LabelCheckTokenStatus>
            <CircularLoadingCustomized />
          </BoxLoading>
        </Container>
      )}
    </>
  );
};
