import { FC, Fragment, useEffect, useState, useMemo } from "react";
import { isMobile } from "react-device-detect";
import {
  FooterLinks,
  FooterProps,
  LinkProps,
  SocialMediaLinks,
} from "design-system/domain";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FooterStyled } from "./styled";
import { FooterImages } from "design-system/images";
import { Accordion } from "design-system/components";
import { colors } from "design-system/theme";
import { blockRoutesToShowElement } from "utils/consts/blocked-routes-show-header-and-footer";

const linksGeneralInformationDefault = [
  {
    link: `${process.env.REACT_APP_CONECTA_AG_URL}/politica-de-privacidade`,
    title: "Política de privacidade",
  },
  {
    link: `${process.env.REACT_APP_CONECTA_AG_URL}/termos-de-uso`,
    title: "Termos de uso",
  },
];

const showLinksGeneralInformation = (): LinkProps[] => {
  if (window.location.pathname !== "/cadastrar-notafiscal") {
    return [
      {
        link: `${process.env.REACT_APP_THIS_DNS}/cadastrar-notafiscal`,
        title: "Cadastrar NF",
      },
      ...linksGeneralInformationDefault,
    ];
  }
  return linksGeneralInformationDefault;
};

const footerLinks: FooterLinks = {
  account: [
    { link: `${process.env.REACT_APP_CONECTA_AG_URL}/customer/account/`, title: "Meus dados" },
    { link: `${process.env.REACT_APP_CONECTA_AG_URL}/sales/order/history`, title: "Meus pedidos" },
    { link: `${process.env.REACT_APP_CONECTA_AG_URL}/wishlist`, title: "Favoritos" },
    { link: `${process.env.REACT_APP_CONECTA_AG_URL}/catalog/product_compare`, title: "Comparar Produtos" },
  ],
  basfContent: [
    { link: `${process.env.REACT_APP_CONECTA_AG_URL}/faq`, title: "Ajuda" },
    { link: `${process.env.REACT_APP_CONECTA_AG_URL}/quem-somos`, title: "Sobre o Conecta" },
    { link: `${process.env.REACT_APP_CONECTA_AG_URL}/contato`, title: "Contato" },
  ],
  generalInformation: [...linksGeneralInformationDefault],
  products: [
    { link: `${process.env.REACT_APP_CONECTA_AG_URL}/todos-os-produtos`, title: "Todos os produtos" },
    { link: `${process.env.REACT_APP_CONECTA_AG_URL}/catalogsearch/advanced`, title: "Pesquisa Avançada" },
  ],
};

const socialMediaLinks: SocialMediaLinks = {
  facebook: "https://www.facebook.com/plataforma.conecta/",
  instagram: "https://www.instagram.com/conecta_ag/",
};

const Footer: FC<FooterProps> = ({ $acceptedAgrega, $setAcceptedAgrega, className }) => {
  const [persistRoot, setPersistRoot] = useState(localStorage.getItem("persist:root"));
  const [optinAgrega, setOptinAgrega] = useState(localStorage.getItem("optinAgrega"));

  useEffect(() => {
    const fetchOptinInAgrega = () => {
      try {
        setPersistRoot(localStorage.getItem("persist:root"));

        if (persistRoot) {
          const root = JSON.parse(persistRoot);
          const user = JSON.parse(root.user);

          if (user.optInAgrega === "true") {
            $setAcceptedAgrega(true);
          }
        }
      } catch (error) {
        console.log(error);
        $setAcceptedAgrega(false);
      }
    };
    fetchOptinInAgrega();
  }, [persistRoot]);

  useEffect(() => {
    setOptinAgrega(localStorage.getItem("optinAgrega"));

    if (optinAgrega) {
      $setAcceptedAgrega(true);
    }
  }, [optinAgrega]);

  const authFooterLinks = useMemo(() => ({
    ...footerLinks,
    generalInformation: showLinksGeneralInformation(),
  }), [persistRoot]);

  const renderLinks = (links: LinkProps[]) => {
    return links.map((item, key) => (
      <FooterStyled.ContainerItemAndSquare key={key}>
        <FooterStyled.LinkItems href={item.link} target={item.title === "Cadastrar NF" ? "_self" : "_blank"}>
          {item.title}
        </FooterStyled.LinkItems>
      </FooterStyled.ContainerItemAndSquare>
    ));
  };

  const conectaLogo = () => (
    <FooterStyled.ContainerLogoConecta>
      <FooterStyled.FooterLogoConecta src={FooterImages.logoConecta} />
      <FooterStyled.textFooterLogoConecta>
        O Conecta traz uma nova forma de se relacionar e de estar conectado
        com as diversas possibilidades de negócios que o Agronegócio e Mercado
        de Controle de Pragas oferecem.
      </FooterStyled.textFooterLogoConecta>
    </FooterStyled.ContainerLogoConecta>
  );

  const socialMedia = () => (
    <FooterStyled.ContainerIcons className="flex">
      <a href={socialMediaLinks.facebook} rel="noreferrer" target="_blank">
        <FooterStyled.FacebookLogoFooter src={FooterImages.facebookLogo} alt="Logo Facebook" />
      </a>
      <a href={socialMediaLinks.instagram} rel="noreferrer" target="_blank">
        <FooterStyled.InstagramLogoFooter src={FooterImages.instagramLogo} alt="Logo Instagram" />
      </a>
    </FooterStyled.ContainerIcons>
  );

  const safeContent = () => (
    <FooterStyled.SafeContent>
      <FooterStyled.ContainerLogoSSL>
        <FooterStyled.ContainerSecurity>
          <FooterStyled.Security>
            <FooterStyled.SecurityLogo src={FooterImages.securityLogo} />
            <FooterStyled.SecurityTextFooter>
              Segurança que você precisa para sua compra.
            </FooterStyled.SecurityTextFooter>
          </FooterStyled.Security>
          <FooterStyled.SecurityTextFooter2>
            Temos selos de segurança para cuidar de você em cada etapa da sua compra.
          </FooterStyled.SecurityTextFooter2>
        </FooterStyled.ContainerSecurity>

        {isMobile && (
          <FooterStyled.SecurityTextFooter3>
            CERTIFICADOS DE SEGURANÇA
          </FooterStyled.SecurityTextFooter3>
        )}

        <FooterStyled.ContainerSafeLogoFooter>
          <FooterStyled.ImageSSL src={FooterImages.SSLLogo} />
          <FooterStyled.ImageSSL style={{ marginLeft: "10px" }} src={FooterImages.safeLogo} />
        </FooterStyled.ContainerSafeLogoFooter>
      </FooterStyled.ContainerLogoSSL>
    </FooterStyled.SafeContent>
  );

  const copyRightContent = () => (
    <FooterStyled.CopyrightContent>
      <FooterStyled.Copyright>
        Copyright © BASF {new Date().getUTCFullYear()}
      </FooterStyled.Copyright>
      <FooterStyled.Address>
        BASF S.A., Avenida Nações Unidas, 14.171, Torre Cristal, 04794-000 São Paulo,
        <br /> Brazil - CNPJ 48539407/0001-18
      </FooterStyled.Address>
    </FooterStyled.CopyrightContent>
  );

  const renderAccordion = (title: string, content: JSX.Element) => (
    <Accordion
      accordionContent={content}
      accordionTitle={title}
      accordionIcon={<ExpandMoreIcon style={{ color: "red", width: "30px" }} />}
    />
  );

  return (
    <FooterStyled.Container className={className}>
      {blockRoutesToShowElement.includes(window.location.pathname) ? (
        <Fragment />
      ) : isMobile ? (
        <>
          {conectaLogo()}
          {socialMedia()}
          {renderAccordion("MINHA CONTA", renderLinks(footerLinks.account)[0])}
          {renderAccordion("NOSSOS PRODUTOS", renderLinks(footerLinks.products)[0])}
          {renderAccordion("INSTITUCIONAL", renderLinks(footerLinks.basfContent)[0])}
          {renderAccordion("INFORMAÇÕES GERAIS", renderLinks($acceptedAgrega ? authFooterLinks.generalInformation : footerLinks.generalInformation)[0])}
          {safeContent()}
          {copyRightContent()}
        </>
      ) : (
        <>
          <FooterStyled.ContainerContentDesktop>
            <FooterStyled.ContainersDesk>{conectaLogo()}</FooterStyled.ContainersDesk>
            <FooterStyled.ContainersDesk>
              <FooterStyled.ConectaTitle>MINHA CONTA</FooterStyled.ConectaTitle>
              {renderLinks(footerLinks.account)}
            </FooterStyled.ContainersDesk>
            <FooterStyled.ContainersDesk>
              <FooterStyled.ConectaTitle>NOSSOS PRODUTOS</FooterStyled.ConectaTitle>
              {renderLinks(footerLinks.products)}
            </FooterStyled.ContainersDesk>
            <FooterStyled.ContainersDesk>
              <FooterStyled.ConectaTitle>INSTITUCIONAL</FooterStyled.ConectaTitle>
              {renderLinks(footerLinks.basfContent)}
            </FooterStyled.ContainersDesk>
            <FooterStyled.ContainersDesk>
              <FooterStyled.ConectaTitle>INFORMAÇÕES GERAIS</FooterStyled.ConectaTitle>
              {renderLinks($acceptedAgrega ? authFooterLinks.generalInformation : footerLinks.generalInformation)}
            </FooterStyled.ContainersDesk>
            <FooterStyled.ContainersDesk>
              <FooterStyled.ConectaTitle>REDES SOCIAIS</FooterStyled.ConectaTitle>
              {socialMedia()}
            </FooterStyled.ContainersDesk>
          </FooterStyled.ContainerContentDesktop>
          <hr style={{ borderColor: colors.neutral.gray04, marginTop: "10px" }} />
          <FooterStyled.ContainersDesk>{safeContent()}</FooterStyled.ContainersDesk>
          <FooterStyled.ContainersDesk>{copyRightContent()}</FooterStyled.ContainersDesk>
        </>
      )}
    </FooterStyled.Container>
  );
};

export default Footer;
