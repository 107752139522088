interface PlusIconProps {
    $disabled: boolean
}

export const PlusIcon = (props: PlusIconProps) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49998 8.39136H3.49998M8.49998 13.3914V8.39136V13.3914ZM8.49998 8.39136V3.39136V8.39136ZM8.49998 8.39136H13.5H8.49998Z"
        stroke={props.$disabled ? "#AAAAAA" : "#EC0818"}
        strokeWidth="1.58308"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
