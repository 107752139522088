import { BannersImagesList } from "domain/models/banners/list";
import styled from "styled-components";

export const ContainerHome = styled.section`
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: space-between;
	max-height: 100%;
	margin: 0px auto;
	width: 100%;
	align-items: flex-start;
`;

export const CylindricalButton = styled.button`
  width: fit-content;
  height: 50px;
  border-radius: 25px;
  background-color: #FF002C;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

export const ContentHome = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 5rem;

	@media (min-width: 1196px) {
		flex-direction: row;
		align-items: flex-start;
	}

`;

export const TextHome = styled.div`
	background-color: #fff;
	padding-top: 150px;
	padding-left: 100px;
	width: 50%;
	position: relative;
	cursor: pointer;
	
	@media (max-width: 800px) {
		padding-left: 10px;
		width: auto;
        margin-top: -90px;
        margin-bottom: 640px;
	}
	h4 {
		font-family: "PoppinsBold";
		font-size: 20px;
		color: #282525;
	}
	h1 {
		font-family: "PoppinsBold";
		font-size: 60px;
		color: #282525;
		position: absolute;
		background: #fff;
		width: 500px;
		line-height: 70px;
		z-index: 2;
		@media (max-width: 800px) {
			width: auto;
		}
	}
	h2 {
		font-family: "PoppinsBold";
		font-size: 32px;

		color: #282525;

		@media (max-width: 800px) {
			margin-top: 10px;
		}
	}
	p {
		font-family: "PoppinsMedium";
		font-size: 16px;
		line-height: 22px;
		color: #555555;
		width: 370px;
		margin-top: 20px;
	}
	.logo-conecta-home {
		width: 400px;
		@media (max-width: 500px) {
			width: 90%;
		}
	}
`;

export const BannerHome = styled.div<{$banners: BannersImagesList}>`
	background-image: url(${props => props.$banners.mobileBannerUrl || '/img/banner-home.jpg'});
	position: relative;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 620px;
	margin-top: -38rem;

	@media (min-width: 768px) {
		background-image: url(${props => props.$banners.tabletBannerUrl || '/img/banner-home.jpg'});
	}
	@media (min-width: 1196px) {
		background-image: url(${props => props.$banners.desktopBannerUrl || '/img/banner-home.jpg'});
		height: 680px;
		margin-top: 0;
	}
	@media (min-width: 2560px) {
		background-image: url(${props => props.$banners.wideBannerUrl || '/img/banner-home.jpg'});
	}

	cursor: pointer;
`;

export const OrangeSection = styled.section`
	background-color: #ececec;
	position: relative;
	top: -200px;
`;

export const ContainerOrangeHome = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-height: 100%;
	margin: 0px auto;
	width: 100%;
	height: 100%;
	align-items: flex-start;
	max-width: 83%;
	padding: 100px 0;
	color: #282525;

	@media (max-width: 800px) {
		flex-direction: column;
	}

	.title-orange {
		color: #282525;
		font-size: 32px;
		font-weight: bold;
		margin-bottom: 21px;
		font-family: 'PoppinsExtraBold';
	}

	.text-orange {
		font-size: 16px;
		margin-bottom: 12px;
	}

	.bold-text-orange {
		font-size: 22px;
		font-family: 'PoppinsBold';
		color: #555555;
	}

	.how-works {
		width: 25%;

		@media (max-width: 800px) {
			width: 100%;
			z-index: 5;
			margin-bottom: 30px;
			text-align: center;
		}
	}

	.container-cards{
		width: 69%;
		position: relative;
		z-index: 2;

		@media (max-width: 800px) {
			width: auto;
    		margin: auto;
		}
	}

	.cards {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		gap: 20px;

		@media (max-width: 1024px) {
			flex-direction: column;
		}
	}

	.link-card {
		@media (max-width: 1024px) {
			width: 100%;
		}
	}

	.card-body {
		background: #fff;
		box-shadow: 0px 8px 24px #0000001f;
		border-radius: 10px;
		width: 252px;
		height: 238px;
		padding: 20px 40px;
		position: relative;
		cursor: pointer;

		img {
			display: inline;
		}

		@media (max-width: 1100px) {
			
			width: 100%;
		}

		@media (max-width: 1024px) {
			padding: 20px 5px;
			text-align: center;
		}

		&:hover {
			background: linear-gradient(59deg, rgb(255 255 255) 0%, rgb(246 246 246) 100%) 0% 0% no-repeat padding-box padding-box transparent;
		}
	}

	.arrow-ball {
		position: absolute;
		right: -43px;
		z-index: 5;
		top: 110px;
		
		@media (max-width: 1024px) {
			position: absolute;
			top: initial;
			bottom: -30px;
			right: initial;
			margin-left: -20px;
			transform: rotate(90deg);
			display: inline;
		}
	}

	.title-card {
		font-size: 22px;
		line-height: 22px;
		font-family: 'PoppinsBold';
		width: 110px;

		@media (max-width: 1366px) {
			width: auto;
		}
	}

	.text-card {
		font-size: 15px;
		line-height: 18px;
		margin-top: 20px;
		width: 188px;

		@media (max-width: 1366px) {
			width: auto;
		}

		@media (max-width: 1100px) {
			font-size: 13px;
		}
	}

	.red-button {
		width: 100%;
		border: 1px solid;
		background: #EC0818 0% 0% no-repeat padding-box;
		border: 1px solid #EC0818;
		border-radius: 30px;
		margin-top: 60px;
		margin-bottom: 56px;
		padding: 15px;
		text-align: center;
		font-family: 'PoppinsBold';
		@media (max-width: 800px) {
			width: auto;
		}
		max-width: 274px;
	}
`;

export const ContainerDefault = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	max-width: 1100px;
    margin: auto;
	gap: 20px;

	@media (max-width: 1200px) {
		flex-wrap: wrap;
		justify-content: space-around;
	}

	h2 {
		font-family: 'PoppinsExtraBold';
		letter-spacing: -0.96px;
		color: #282525;
		font-size: 32px;
		line-height: 34px;
		margin-top: 0px;
		margin-bottom: 40px;
		@media (max-width: 800px) {
			width: auto;
			line-height: 37px;
    		padding: 10px;
    		text-align: left;
		}
	}

	.img-categories {
		width: 100%;

		@media (max-width: 1300px) {
			width: 250px;
		}
	}

	.question-box {
		border: 1px solid ececec;
		background: #FFFFFF;
		border: 1px solid #ECECEC;
		border-radius: 10px;
		margin-bottom: 16px;
		width: calc(33.3333% - 30px);
    	min-height: 350px;

		@media (max-width: 1200px) {
			width: 31%;
		}	
		
		@media (max-width: 800px) {
			width: 90%;
		}
		

		.question-title {
			color: #282525;
			font-size: 16px;
			line-height: 22px;
			padding: 25px;
			font-family: 'PoppinsBold';
		}

		.question-answer {
			color: #555555;
			font-size: 16px;
			line-height: 22px;
			padding: 25px;
		}
	}

	.question-small-box {
		width: 29%;
		background: #EC0818;
		border-radius: 10px;
		font-size: 16px;
		line-height: 22px;
		color: #fff;
		display: flex;
    	justify-content: space-between;
    	align-items: center;
		padding: 20px 25px;
		width: calc(29.3333% - 30px);

		a {
			display: flex;
		}

		@media (max-width: 1200px) {
			width: 300px;
		}	
		
		@media (max-width: 800px) {
			width: 78%;
    		display: block;
			justify-content: center;
			display: flex;
		}

		.text-small-box {
			width: 275px;
    		margin-left: 20px;
		}
	}
`;

export const ContainerAgrega = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	top: -100px;
	max-width: 1100px;
	margin: auto;

	@media (max-width: 1000px) {
		flex-direction: column;
		justify-content: space-around;
		max-width: 960px;
	}

	.img-agrega {
		@media (max-width: 800px) {
			margin: auto;
			text-align: center;
		}
	}

	.img-full {
		max-width: 100%;
		@media (max-width: 800px) {
			width: 80%;
		}
	}

	.slogan-agrega {
		width: 50%;
		align-items: center;
		display: flex;
  		flex-direction: column;

		@media (max-width: 1100px) {
			width: auto;
		}

		@media (max-width: 800px) {
			width: 100%;
		}
	}

	.title-agrega {
		font-size: 32px;
		line-height: 68px;
		font-family: PoppinsExtraBold;
		width: 500px;
		margin-bottom: 10px;

		@media (max-width: 800px) {
			width: auto;
			margin-top: 20px;
			line-height: 37px;
			padding: 10px;
			text-align: left;
		}
	}

	.text-agrega {
		font-size: 16px;
		line-height: 22px;
		color: #555555;
		width: 500px;

		@media (max-width: 800px) {
			width: auto;
			line-height: 30px;
			padding: 10px;
			text-align: left;
		}
	}
	.CylindricalButton {
		margin-top: 20px;
	  }
	  .text-wrapper {
		margin-top: 0px;
	  }
`;
