/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpClient } from "data/protocols/http/http-client";
import { StorageHelper } from "utils/functions/storage/storage";
import { RedirectUserAgregaLogged } from "./redirect-user-agrega-logged";
import { CampaignListHttpResponse } from "domain/models/campaign/list";
import { AgregaTypes } from "pages/ProgramDetails/ExclusiveAgrega/AditionalInformation/types";

const storageHelper = new StorageHelper();
export class Http<HttpParams = any, HttpResponse = any>
	implements HttpClient<HttpParams, HttpResponse>
{
	async request(
		params?: HttpParams
	): Promise<{ response: HttpResponse; statusCode: number; success: boolean }> {
		const response: AxiosResponse<HttpResponse> = await axios.request(
			params as AxiosRequestConfig
		);
		return {
			response: response.data,
			statusCode: response.status,
			success: response.status >= 200 && response.status <= 299,
		};
	}
}
const Subscription: AxiosRequestConfig = {
	headers: {
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_SUBSCRIPTION_KEY
		),
		["X-Session-Id"]: storageHelper.get("sessionKey"),
	},
};

const SubscriptionAgregaAdmin: AxiosRequestConfig = {
	headers: {
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_AGREGA_ADMIN_SUBSCRIPTION_KEY
		),
		["X-Session-Id"]: storageHelper.get("sessionKey"),
	},
};

const SubscriptionParticipant: AxiosRequestConfig = {
	headers: {
		Authorization: `Bearer ${storageHelper.get("token")}`,
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_AGREGA_SUBSCRIPTION_KEY
		),
		["X-Session-Id"]: storageHelper.get("sessionKey"),
	},
};
const SubscriptionProduct: AxiosRequestConfig = {
	headers: {
		Authorization: `Bearer ${storageHelper.get("token")}`,
		["Ocp-Apim-Subscription-Key"]: String(
			"d6218dbe291747c4b24db5feb3019020"
		),
		["X-Session-Id"]: storageHelper.get("sessionKey"),
	},
};

export const api = {
	checkCampaignExtractExists: async (
		document_number: string | number | null | undefined,
		id: string | undefined
	) => {
		try {
			const response = await axios.head(
				`${process.env.REACT_APP_EXTRACT_BLOB_URL}${document_number}_${id}.xlsx`
			);
			return response.status === 200;
		} catch {
			return false;
		}
	},
	fetchAllCampaigns: async (
		queryParams: string
	): Promise<CampaignListHttpResponse> => {
		const campaignsList = await axios.get<CampaignListHttpResponse>(
			`${process.env.REACT_APP_API_CONECTA_AG_URL}/conectapontosbackend/campaign/getPaginated?Sort.Sortby=startDate&Sort.Direction=Desc&${queryParams}`,
			Subscription
		);

		return campaignsList?.data;
	},
	fetchCampaign: async (id: string | undefined) => {
		return axios.get(
			`${process.env.REACT_APP_API_CONECTA_AG_URL}/conectapontosbackend/campaign/detail/getByCampaignId/${id}`,
			Subscription
		);
	},
	fetchGetDistributors: async (
	): Promise<any> => {
		const data = await axios.get(
			`${process.env.REACT_APP_BASE_URL_API}hotsite/distribuitor/getall?paginate.pageIndex=0&paginate.pageSize=100000000&Status=1`,SubscriptionAgregaAdmin	
		);
		return data?.data;
	},
  fetchGetProducts: async (
	): Promise<any> => {
		const data = await axios.get(
			`https://api-conecta.vertem.com/hotsite/product/getall`,SubscriptionProduct	
		);
		return data?.data;
	},
  fetchGetSearchDistributors: async (distributor: string, uf: string, city: string
	): Promise<any> => {
		const data = await axios.get(
			`${process.env.REACT_APP_BASE_URL_API}hotsite/distribuitor/getall?paginate.pageIndex=0&paginate.pageSize=100000000&Status=1&name=${distributor}&city=${city}&state=${uf}`,SubscriptionAgregaAdmin	
		);
		return data?.data;
	},
  fetchOptins: async (document_number: string | number | null | undefined) => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL_API}/participant/participants/optin/${document_number}`,
      SubscriptionParticipant
    );
  },
  getBanners: async () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL_API}admin/banners`,
      SubscriptionAgregaAdmin
    );
  },
  getChannels: async () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL_API}hotsite/distribuitor/matrizes`,
      SubscriptionAgregaAdmin
    );
  },
  getCultures: async () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL_API}participant/culture/cultures`,
      SubscriptionAgregaAdmin
    );
  },
  postOptinAgrega: async (payload: AgregaTypes) => {    
    return await axios.post(
      `${process.env.REACT_APP_BASE_URL_API}participant/participants/updateParticipantVertemContext`,
      payload,
      { headers: {
        Authorization: `Bearer ${storageHelper.get<string>("token")}`,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": String(
          process.env.REACT_APP_AGREGA_ADMIN_SUBSCRIPTION_KEY
        ),
        "X-Session-Id": storageHelper.get<string>("sessionKey"),
      } }
    );
  },
  postOptinCampaing: async (form: {
    ConectaProgramId: string | number | undefined | null;
    DocumentNumber: string | number | undefined | null;
    PromotionCode: string | null;
  }) => {
    return axios.post(
      `${process.env.REACT_APP_BASE_URL_API}participant/participants/optinProgramConectaPontos`,
      form,
      SubscriptionParticipant
    );
  },
  redirectUserAgregaLogged: async (userParams: RedirectUserAgregaLogged) => {
    const httpResult = await axios.post(
      `${process.env.REACT_APP_BASE_URL_API}sso/auth`,
      { ...userParams, secretKey: process.env.REACT_APP_API_SECRET_KEY },
      {
        headers: {
          ["Ocp-Apim-Subscription-Key"]: String(
            process.env.REACT_APP_AGREGA_ADMIN_SUBSCRIPTION_KEY
          ),
          ["X-Session-Id"]: storageHelper.get("sessionKey"),
        },
      }
    );

		return httpResult?.data?.response?.data?.redirectUrl;
	}
};
