import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Product {
  category: string;
  point: string;
  productName: string;
}

interface ProductState {
  filteredProducts: Product[];
  products: Product[];
}

const initialState: ProductState = {
  filteredProducts: [],
  products: [],
};

const productsSlice = createSlice({
  initialState,
  name: "product",
  reducers: {
    clearProducts(state) {
      state.products = [];
      state.filteredProducts = [];
    },
    filterProducts(
      state,
      action: PayloadAction<{ category?: string; point?: string; productName?: string }>
    ) {
      const { category, productName } = action.payload;

      if (!category && !productName) {
        state.filteredProducts = state.products;
        return;
      }

      state.filteredProducts = state.products.filter((product) => {
        const matchesCategory = category ? product.category === category : true;
        const matchesProductName = productName
          ? product.productName.toLowerCase().includes(productName.toLowerCase())
          : true;

        return matchesCategory && matchesProductName;
      });
    },
    setProducts(state, action: PayloadAction<Product[]>) {
      state.products = action.payload;
      state.filteredProducts = action.payload;
    },
  },
});

export const { setProducts, clearProducts, filterProducts } = productsSlice.actions;
export default productsSlice.reducer;
