/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sort-keys */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { capitalizeWords, JWTDecode, StatusRequest } from "utils";
import { Http } from "data/protocols/services/api";
import { HttpAuthCheckToken } from "services/auth/check-token";
import { useTitle } from "hooks";
import { populateUser, setScore } from "store/slices/user";
import { StorageHelper } from "utils/functions/storage/storage";
import { HttpGetUserScore } from "services/account-user/score";
import { LoadingMessage } from "design-system/components/LoadingMessage";
import styled from "styled-components";

interface AuthPageProps {
	$setAcceptedAgrega: (accepted: boolean) => void;
}

const Container = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AuthPage = ({$setAcceptedAgrega}: AuthPageProps): JSX.Element => {
	const [statusTokenValidation, setStatusTokenValidation] =
		useState<StatusRequest>(StatusRequest.FETCHING);
	const urlParams = new URLSearchParams(useLocation().search);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useTitle("SSO - Análise");



	const handleOptinCommunication = (
		opt_in_comunication: boolean | number | null | undefined | string
	): boolean | null => {
		if (opt_in_comunication === 1 || opt_in_comunication === "1") {
			return true;
		}

		if (opt_in_comunication === null || opt_in_comunication === "null") {
			return null;
		}
		return false;
	};

	const handleToken = async (): Promise<void> => {
		try {
			const accessToken = urlParams.get("accessToken");
			if (!accessToken) {
				throw "Token not provided";
			}
			const sessionId = urlParams.get("sessionId");
			const http = new Http();
			const jWTDecode = new JWTDecode();
			const httpAuthCheckToken = new HttpAuthCheckToken(http, jWTDecode);
			const storageHelper = new StorageHelper();
			const httpGetUserScore = new HttpGetUserScore(http, storageHelper);

			const { success, userInfo } = await httpAuthCheckToken.check({
				accessToken,
			});
			if (!success) {
				setStatusTokenValidation(StatusRequest.ERROR);
				return;
			}

			const {
				documentNumber,
				optInAgrega,
				optInPoints,
				optinTermsAndPrivacy,
				optinComunication,
				promotionCode,
				...rest
			} = userInfo;

			$setAcceptedAgrega(optInAgrega === "true");

			storageHelper.set<string>("token", String(userInfo.token));
			storageHelper.set<string>("sessionKey", String(sessionId));

			new Promise((resolve) => {
				resolve(
					dispatch(
						populateUser({
							...rest,
							optInAgrega: optInAgrega,
							document_number: documentNumber,
							is_logged: true,
							name: capitalizeWords(String(userInfo.name)),
							opt_in_points:
								optInPoints === "True" ||
								optInPoints === "true" ||
								optInPoints === (true as unknown as string),
							opt_in_terms_and_privacy:
								optinTermsAndPrivacy === "True" ||
								optinTermsAndPrivacy === "true" ||
								optinTermsAndPrivacy === (true as unknown as string),
							opt_in_comunication: handleOptinCommunication(optinComunication),
							promotion_code: promotionCode,
							session_key: sessionId,
						})
					)
				);
			}).then(() => {
				httpGetUserScore
					.getScoreByUser({
						data: {
							documentNumber: String(userInfo.documentNumber),
							token: String(userInfo.token),
							xSessionId: String(sessionId),
						},
					})
					.then((score: number) => {
						dispatch(setScore(score));
						setTimeout(() => {
							navigate("/");
						}, 3000);
					})
					.catch(() => {
						setTimeout(() => {
							navigate("/");
						}, 3000);
					});
			});
			setStatusTokenValidation(StatusRequest.SUCCESS);
		} catch (error) {
			setStatusTokenValidation(StatusRequest.ERROR);
		}
	};

	useEffect(() => {
		if (statusTokenValidation === StatusRequest.FETCHING) {
			handleToken();
		}
	}, [statusTokenValidation]);

	const defineContent = () => {
		switch (statusTokenValidation) {
			case StatusRequest.FETCHING:
				return <LoadingMessage message="Validando token" authLoading/>
			case StatusRequest.SUCCESS:
				return <LoadingMessage message="Token validado com sucesso" authLoading/>
			case StatusRequest.ERROR:
				return <LoadingMessage message="Erro ao validar Token" authLoading/>
		}
	};
	return <Container>{defineContent()}</Container>;
};

export default AuthPage;
