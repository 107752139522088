import React from "react";
import { CloseIcon, TagContainer, TagText } from "./styled";
import { TagProps } from "./types";

export const TagCultureAndPlantedArea = ({
  name,
  $hectar,
  onClick,
}: TagProps): React.JSX.Element => {
  return (
    <TagContainer onClick={onClick}>
      <TagText>{`${name} ${$hectar} ha`}</TagText>
      <CloseIcon>×</CloseIcon>
    </TagContainer>
  );
};
