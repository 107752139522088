import styled from "styled-components";
import { CircularLoading } from "design-system/components";
import { ErrorIcon } from "design-system/icons/geral-icons";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Arial, Helvetica, sans-serif;
`;

export const LabelCheckTokenStatus = styled.p`
    font-size: 20px;
    font-weight: 700;
`;

export const BoxLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CircularLoadingCustomized = styled(CircularLoading)`
  margin-top: 2rem;
`;

export const ErrorIconCustomized = styled(ErrorIcon)`
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 45px !important;
  }
  margin-top: 15px;
`;