/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { api } from 'data/protocols/services/api';
import { useAppDispatch, useAppSelector } from "../store/selectors/distributor";
import { setProducts } from "../store/slices/product";

const useProducts= () => {
  const dispatch = useAppDispatch();
  const productsData =  useAppSelector((state) => state.product.filteredProducts);
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response: any = await api.fetchGetProducts();
        dispatch(setProducts(response));
      } catch (err: any) {
        console.log("error", err)
      }
    };
    fetchProducts();
  }, []); 
  return { productsData };
};

export default useProducts;
