import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { appReducer } from "store";
import { colors, GlobalStyle } from "design-system/theme";
import { Footer, Header, Layout } from "./design-system/components";

import { ProviderPage } from "./pages/ProviderPage";
import {
  TermAndConditionPage,
  InterceptionAcceptanceRegulation,
} from "./pages/TermAndCondition";
import { LegacyExtractPage } from "./pages/LegacyExtractPage";
import { PageElo } from "./pages/PageElo";
import { RegisterPage } from "./pages/RegisterPage";
import { AuthPage } from "./pages/Auth";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ProgramDetails } from "./pages/ProgramDetails";
import { LogoutPage } from "./pages/Logout";
import { MaintenancePage } from "./pages/Elo";
import { RegisterNF } from "pages/RegisterNF";
import { useDecodeToken } from "hooks/useDecodeToken";
import Home from "./pages/Home";
import ProgramPage from "./pages/ProgramPage";
import { clarity } from "react-microsoft-clarity";

const App = (): JSX.Element => {
  const persistor = persistStore(appReducer);

  const [acceptedAgrega, setAcceptedAgrega] = useState(false);

  if (process.env.REACT_APP_CONECTAPONTOS_MAINTENANCE === "isMaintenance") {
    return <MaintenancePage />;
  }

  useEffect(() => {
    clarity.init(String(process.env.REACT_APP_CLARITY_KEY));
    clarity.consent();
    clarity.setEvent('pageView');
    
  }, [window.location.pathname]);

  const isLogged =
    useDecodeToken(localStorage.getItem("token") as string)?.exp >
    Date.now() / 1000;

  useEffect(() => {
    if (!isLogged && window.location.pathname === "/cadastrar-notafiscal") {
      window.location.href = "/";
    }
  }, []);

  return (
    <Provider store={appReducer}>
      <PersistGate persistor={persistor}>
        <GlobalStyle />
        <Layout>
          <Header
            hideFirstRowHeader={false}
            backgroundColor={colors.generics.white}
          />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/distribuidores" element={<ProviderPage />} />
              <Route path="/programas-parceiros" element={<ProgramPage />} />
              <Route
                path="/programa/:programId/:id"
                element={<ProgramDetails />}
              />
              <Route path="/regulamento" element={<TermAndConditionPage />} />
              <Route
                path="/resgatar"
                element={<InterceptionAcceptanceRegulation />}
              />
              <Route path="/elo" element={<PageElo />} />
              <Route path="/extrato" element={<LegacyExtractPage />} />
              <Route path="/cadastro" element={<RegisterPage />} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route
                path="auth"
                element={<AuthPage $setAcceptedAgrega={setAcceptedAgrega} />}
              />
              <Route path="/cadastrar-notafiscal" element={<RegisterNF />} />
              <Route path="/vouchers" element={<></>} />
            </Routes>
          </BrowserRouter>
          <Footer $acceptedAgrega={acceptedAgrega} $setAcceptedAgrega={setAcceptedAgrega} />
        </Layout>
      </PersistGate>
    </Provider>
  );
};

export default App;
