/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  DropListButton,
  DropListContainer,
  DropListItem,
  DropListMenu,
} from "./styled";
import { Col } from "antd";
import { DropDownProps, OptionProps } from "./types";
import { colors } from "themes/colors";

export const Dropdown = ({
  id,
  options,
  width,
  onSelect,
  selected,
  optionDefault,
  $screenWidth,
  placeholder,
}: DropDownProps): React.JSX.Element => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionProps | null>(
    null
  );
  const [highlightedOption, setHighlightedOption] =
    useState<OptionProps | null>(null);

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  const handleToggle = () => setIsOpen((prevState) => !prevState);

  const handleOptionClick = (option: OptionProps) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleMouseEnter = (option: OptionProps) =>
    setHighlightedOption(option);

  const handleMouseLeave = () => setHighlightedOption(null);

  const truncateOptionName = (name: string) => {
    if (Number($screenWidth) >= 1196) {
      return name?.length > 40 ? name.substring(0, 40) + "..." : name;
    }
    return name?.length > 15 ? name.substring(0, 15) + "..." : name;
  };

  const getDisplayOption = (): string => {
    if ((selectedOption?.nameGroup || selectedOption?.name) !== '') {
      if (selectedOption?.nameGroup) {
        return truncateOptionName(selectedOption.nameGroup);
      }
      return truncateOptionName(selectedOption?.name as string);
    } else if (selected.name as string !== '') {
      return truncateOptionName(selected.name);
    } else if ((optionDefault?.nameGroup || optionDefault?.name) !== '') {
      return optionDefault?.nameGroup
        ? truncateOptionName(optionDefault.nameGroup)
        : truncateOptionName(optionDefault?.name);
    }
    return placeholder ?? "Selecione...";
  };
  

  const customFlex = {
    alignItems: "center",
    display: "flex",
    justifyContent: "end",
  };

  const cultureItems = selected?.name;
  const channelItems = selectedOption?.nameGroup ?? optionDefault?.nameGroup;

  const colorSpan = {
    color: selectedOption || selected ? colors.black : colors.gray.medium,
  };

  return (
    <DropListContainer width={width} id={id} aria-labelledby={id}>
      <DropListButton
        data-testid="dropdown-button"
        id={id}
        $screenWidth={$screenWidth}
        onClick={handleToggle}
        style={customFlex}
      >
        <Col md={20} xs={20}>
          <span style={colorSpan} title={cultureItems || channelItems}>
            {getDisplayOption()}
          </span>
        </Col>
        <Col md={4} xs={4}>
          <div style={customFlex}>
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.47509 7.03046L7.475 7.03054C7.34658 7.14962 7.17551 7.21429 6.99998 7.21429C6.82478 7.21429 6.65341 7.14997 6.52489 7.03045L7.47509 7.03046ZM7.47509 7.03046L13.0101 1.8919L13.0101 1.89186C13.2828 1.63854 13.2823 1.2212 13.0088 0.968474L13.0087 0.968428C12.7446 0.724497 12.3232 0.725137 12.0598 0.96967L12.0598 0.969674L6.99998 5.66701L1.94023 0.969501L1.9402 0.969475C1.67677 0.724989 1.25554 0.724349 0.991393 0.968232L0.991322 0.968298C0.856542 1.09285 0.785714 1.26016 0.785714 1.43069C0.785714 1.60072 0.856061 1.76744 0.989979 1.89184L0.990023 1.89188L6.52471 7.03029L7.47509 7.03046Z"
                fill={colors.gray.low}
                stroke={colors.gray.low}
                strokeWidth="0.428571"
              />
            </svg>
          </div>
        </Col>
      </DropListButton>
      {isOpen && (
        <DropListMenu as="ul">
          {options.map((option: any) => {
            return (
              <DropListItem
                as="li"
                $screenWidth={$screenWidth}
                key={option.id}
                id={option.id}
                onClick={() => handleOptionClick(option)}
                onMouseEnter={() => handleMouseEnter(option)}
                onMouseLeave={handleMouseLeave}
                $isHighlighted={highlightedOption === option}
              >
                {option.nameGroup ?? option.name}
              </DropListItem>
            )
          })}
        </DropListMenu>
      )}
    </DropListContainer>
  );
};
